<template>
  <div class="get-mod">
    <div class="get-item" v-for="(item, index) in list" :key="index">
      <div class="title">
        <div class="title-l">{{ item.box_name }}</div>
        <div class="title-r">{{ item.created_at }}</div>
      </div>
      <div class="info">
        <div class="info-l flex">
          <img style="width:0.6rem;margin-right: 0.1rem;" :src="item.cover" alt="">
          {{ item.name }}
        </div>
        <!-- <div class="info-r"><span
            style="color: #ff9900;display: flex;justify-content: center;align-items: center;"><img style="width: 0.2rem;margin-right: 0.05rem;"
              src="@/assets/images/about/user/huobi.png" alt="">{{ item.bean }}</span></div> -->
        <div class="info-r"><span style="color: #ff9900;display: flex;justify-content: center;align-items: center;">
            <Cint :price="item.bean" />
          </span></div>
      </div>
    </div>
    <div class="common-pagination">
      <el-pagination background layout="total, pager" @current-change="handleCurrentChange" :page-size="pageSize"
        :total="total" :hide-on-single-page=true>
      </el-pagination>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {     
      currentPage: 1
    };
  },
  props: {
    list: {
      type: Array,
      default: () => [],
    },
    pageSize: {
      type: [String, Number],
      default: 10
    },
    total: {
      type: [String, Number],
      default: 1
    },
  },
  methods: {
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.currentPage = val
      this.$emit("pageChange", { page: this.currentPage })
    },
  }
};
</script>
<style lang="scss" scoped>
.get-mod {
  .get-item {
    width: 99%;
    margin-bottom: 0.1rem;

    .title {
      width: 100%;
      height: 0.3rem;
      background: url(../../assets/images/about/user/ggbg.png) no-repeat;
      background-size: 100% 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      box-sizing: border-box;
      padding: 0 0.05rem 0 0.05rem;
      font-size: 0.1rem;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #cccccc;
    }

    .info {
      display: flex;
      justify-content: space-between;
      width: 100%;
      height: 0.46rem;
      background: #2b4162;
      font-size: 0.1rem;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #fff;
      box-sizing: border-box;
      padding: 0.025rem 0.093rem 0.024rem 0.068rem;
      align-items: center;
    }
  }
}
</style>
