<template>
  <div class="game-mod">
    <div class="game-item" v-for="(item, index) in list" :key="index">
      <img v-if="item.type_name === '盲盒开箱'" src="../../assets/images/OpenBox/popup/title1.png" class="type" alt="" />
      <img v-else-if="item.type_name === '盲盒对战'" src="../../assets/images/OpenBox/popup/title2.png" class="type" alt="" />
      <img v-else src="../../assets/images/OpenBox/popup/title3.png" class="type" alt="" />
      <div class="text">最大订单</div>
      <div class="per-box">
        <img style="width:80%;height:50%;" :src="item.skins_img" alt="" />
      </div>
      <!-- <div class="price"><img style="width: 0.2rem;margin-right: 0.05rem;" src="@/assets/images/about/user/huobi.png"
          alt="">{{ item.skins_price }}</div> -->
      <div class="price">
        <Cint :price="item.skins_price" />
      </div>
      <div class="num">
        <img src="../../assets/images/OpenBox/popup/icon-spear.png" alt="" />
        X{{ item.skins_num }}
      </div>
      <img src="../../assets/images/OpenBox/popup/line.png" alt="" class="line" />
      <div class="title">{{ item.box_name }}</div>
      <div class="box-price">盲盒价格：{{ item.box_bean }}</div>
      <div class="time">{{ item.created_at }}</div>
    </div>
  </div>
</template>

<script>

export default {
  props: {
    list: {
      type: Array,
      default: () => [],
    },
  },
};
</script>
<style lang="scss" scoped>
.game-mod {
  display: flex;
  width: 4.7rem;

  .game-item {
    width: 1.4rem;
    margin-right: 0.4rem;
    height: 3.8rem;
    background: url(../../assets/images/OpenBox/popup/game-box.png) no-repeat;
    background-size: 100% 100%;
    box-sizing: border-box;
    overflow: hidden;
    text-align: center;
    margin-top: 0.4rem;
    margin: 0.1rem;

    &:nth-child(3n) {
      margin-right: 0;
    }

    &:nth-child(1) {
      margin-top: 0;
    }

    &:nth-child(2) {
      margin-top: 0;
    }

    &:nth-child(3) {
      margin-top: 0;
    }

    .type {
      width: 100%;
      margin-top: 0.43rem;
    }

    .text {
      color: #3399cc;
      font-size: 0.16rem;
      font-weight: 400;
      margin: 0.13rem 0 0;
    }

    .per-box {
      width: 1rem;
      height: 1.1rem;
      background: url(../../assets/images/OpenBox/popup/prop-pic.png) no-repeat;
      background-size: 100% 100%;
      margin: 0 auto;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .price {
      font-size: 0.14rem;
      color: #ff9900;
      margin: 0.1rem 0;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .num {
      font-size: 0.14rem;
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        width: 0.25rem;
        height: 0.2rem;
        margin-right: 0.1rem;
      }
    }

    .line {
      width: 80%;
      margin: 0.05rem auto;
    }

    .title {
      font-weight: 500;
      font-size: 0.14rem;
      color: #fff;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      -o-text-overflow: ellipsis;
    }

    .box-price {
      margin: 0.04rem 0;
      font-size: 0.12rem;
    }

    .time {
      font-size: 0.12rem;
    }
  }
}
</style>
