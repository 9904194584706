<template>
  <div class="per-mod">
    <div class="list-box-wrap">
      <div class="title">
        <div class="text">近7日获得TOP10</div>
      </div>
      <div class="list-box">
        <div class="list-item" v-for="(item, index) in list" :key="index">
          <div class="good-box">
            <div class="good-img">
              <img
                v-if="item.cover"
                style="width: 100%; margin-top: 0.015rem"
                :src="item.cover"
                alt=""
              />
            </div>
            <!-- <i>TOP{{ index + 1 }}</i> -->
          </div>
          <div class="price">{{ item.bean }}</div>
          <div class="name">{{ item.name }}</div>
          <!-- <div class="box-type">{{ item.box_name }}</div> -->
          <div class="box-time">{{ item.created_at }}</div>
          <!-- <div class="pic-name">
            <div class="pic"></div>
            <div class="name">{{ item.get_user?.name || '虚位以待' }}</div>
          </div> -->
        </div>
      </div>
    </div>
    <!-- <div class="info-box">
      <div class="info-l">
        <div class="info-item">
          <img src="../../assets/images/OpenBox/popup/info-box.png" alt="" />
          <img src="../../assets/images/OpenBox/popup/title-box.png" class="info-title" alt="" />
          <div class="text">总次数/总获得</div>
          <div class="text-val">
            <span></span>
            <span>/</span>
            <span></span>
          </div>
        </div>
        <div class="info-item">
          <img src="../../assets/images/OpenBox/popup/info-battle.png" alt="" />
          <img src="../../assets/images/OpenBox/popup/battle.png" class="info-title" alt="" />
          <div class="text">总次数/总获得</div>
          <div class="text-val">
            <span></span>
            <span>/</span>
            <span></span>
          </div>
        </div>
        <div class="info-item">
          <img src="../../assets/images/OpenBox/popup/info-luck.png" alt="" />
          <img src="../../assets/images/OpenBox/popup/title-luck.png" class="info-title" alt="" />
          <div class="text">总次数/总获得</div>
          <div class="text-val">
            <span></span>
            <span>/</span>
            <span></span>
          </div>
        </div>
      </div>
      <div class="info-r">
        <div class="info-r-wrap">
          <img src="../../assets/images/OpenBox/popup/title-data.png" alt="" class="title-data" />
          <div class="data-box">
            <div class="data1">盲盒饰品</div>
            <div class="pig">
              <div class="pig-l">盲盒对战</div>
              <div class="pig-c">
                <div></div>
              </div>
              <div class="pig-r">道具取回</div>
            </div>
          </div>
        </div>
      </div>
    </div> -->
  </div>
</template>

<script>
export default {
  props: {
    list: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      data: [],
      sums: 0, //总价
    };
  },
  created() {
    this.grtdata();
  },
  methods: {
    // 计算总价
    grtdata() {
      this.data = this.list;
      var num = [];
      this.data.map((v) => {
        num.push(parseInt(v.box_bean));
      });
      var sum = num.reduce(function (a, b) {
        return a + b;
      }, 0);
      this.sums = sum;
    },
  },
};
</script>
<style lang="scss" scoped>
.per-mod {
  .list-box-wrap {
    width: 100%;
    display: flex;
    flex-wrap: wrap;

    .title {
      font-size: 0.2rem;
      font-weight: 400;
      margin-bottom: 0.2rem;
    }
    .list-box {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      .list-item {
        // margin-left: 0.1rem;
        width: 1.6rem;
        height: 2.4rem;
        background: url(../../assets/images/classics/falling-box.png) no-repeat;
        background-size: contain;
        overflow: hidden;
        box-sizing: border-box;
        padding-top: 0.36rem;
        text-align: center;
        font-size: 0.26rem;
        font-family: FZZongYi-M05S;
        font-weight: 400;
        color: #66ccff;
        margin-bottom: 0.02rem;
        margin-right: 0.1rem;

        &:nth-child(5n - 4) {
          margin-left: 0;
        }

        .good-box {
          box-sizing: border-box;
          display: flex;
          justify-content: center;
          position: relative;

          .good-img {
            width: 1.07rem;
            height: 0.7rem;
            // background: red;
            border-radius: 50%;
          }

          i {
            position: absolute;
            left: 0.55rem;
            bottom: 0.1rem;
          }
        }

        .price {
          margin-top: 0.2rem;
          font-size: 0.18rem;
        }

        .name,
        .box-type {
          font-size: 0.14rem;
          font-family: Microsoft YaHei;
          height: 0.35rem;
          overflow: visible;
          margin-top: 0.05rem;
        }

        .box-type {
          margin-top: 0.1rem;
          color: #fff;
        }

        .box-time {
          font-size: 0.1rem;
          font-family: Microsoft YaHei;
          color: #fff;
        }

        .pic-name {
          margin-top: 0.09rem;
          width: 1.73rem;
          height: 0.32rem;
          background: #000000;
          border-radius: 0rem 0.11rem 0rem 0.11rem;
          font-size: 0.15rem;
          font-family: Microsoft YaHei;
          color: #cccccc;
          text-align: center;
          line-height: 0.32rem;
          box-sizing: border-box;
          border: 0.01rem solid #004672;
          box-shadow: inset 0px 0px 5px 1px #004672;
          margin-left: auto;
          margin-right: auto;
          display: flex;
          align-items: center;
          justify-content: center;

          .pic {
            width: 0.16rem;
            height: 0.16rem;
            border-radius: 50%;
            background: #fff;
            margin-right: 0.12rem;
          }
        }
      }
    }
  }
}
</style>
