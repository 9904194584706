<template>
  <div class="highlight">
    <div class="center">
      <div class="userinfo">
        <div class="info-l">
          <div class="userhead">
            <img class="user-img" :src="user.avatar" alt="" />
          </div>
          <p>{{ user.name }}</p>
        </div>
        <div class="info-r">
          <img src="../../assets/images/mobile/m-hight-gghk.png" alt="" />
        </div>
      </div>
      <div class="player-tab">
        <div class="tab-item">顶级掉落</div>
      </div>
      <div class="player-box2">
        <game :list="gameList" v-loading.body="loading" element-loading-text="拼命加载中" element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 0.5)" />
      </div>
      <div class="player-tab">
        <div class="tab-item">最近获得</div>
      </div>
      <div class="player-box1">
        <get :list="getList" :page-size="pageSize" @pageChange="listchange" :total="total" v-loading.body="loading" element-loading-text="拼命加载中" element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 0.5)" />
      </div>

      <div class="player-tab">
        <div class="tab-item">近期表现</div>
      </div>
      <div class="player-box3">
        <performance :list="performanceList" v-loading.body="loading" element-loading-text="拼命加载中" element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 0.5)" />
      </div>
    </div>
  </div>
</template>

<script>
import get from "./get";
import performance from "./performance";
import game from "./game";
import { mapState, mapMutations } from "vuex";
import {
  getNewlyGetApi,
  getNewlyHighLightApi,
  getPerformanceApi,
} from "@/network/api.js";
export default {
  components: {
    get,
    performance,
    game,
  },
  props: {
    // uuid: ''
  },
  data() {
    return {
      popupTab: ["最近获得", "盲盒游戏", "近期表现"],
      tabCurrent: 0,
      performanceList: [],
      getList: [],
      gameList: [],
      loading: false,
      page_num: 1, //页码
      pageSize: 10, //每页显示
      total: 0, //总数
    };
  },
  computed: {
    ...mapState(["popupHighLightId", "user"]),
  },
  created() {
    this.tabCurrentChange(0);
    this.getNewlyGet();
    this.getNewlyHighLight();
    this.getPerformance();
  },
  methods: {
    // 最近获得
    getNewlyGet() {
      getNewlyGetApi({ uid: this.popupHighLightId }, this.page_num).then(
        (res) => {
          this.getList = res.data.data.data;
          this.pageSize = res.data.data.per_page;
          this.total = res.data.data.total;
        }
      );
    },
    //最近获得分页
    listchange(v) {
      this.page_num = v.page;
      this.getNewlyGet();
    },
    // 盲盒游戏
    getNewlyHighLight() {
      getNewlyHighLightApi({ uid: this.popupHighLightId }).then((res) => {
        this.gameList = res.data.data;
      });
    },
    // 近期表现
    getPerformance() {
      getPerformanceApi({ uid: this.popupHighLightId }).then((res) => {
        this.performanceList = res.data.data;
      });
    },
    tabCurrentChange(i) {
      this.tabCurrent = i;
      this.loading = true;
      if (i === 0) {
        this.getNewlyHighLight();
      } else if (i === 1) {
        this.page_num = 1;
        this.getNewlyGet();
      } else {
        this.getPerformance();
      }
      this.loading = false;
    },
    ...mapMutations(["User"]),
  },
};
</script>
<style lang="scss" scoped>
.highlight {
  width: 3.75rem;
  margin-top: 0.14rem;

  .center {
    width: 3.4rem;
    margin: 0 auto;

    .userinfo {
      width: 3.4rem;
      height: 1.6rem;
      background: url("../../assets/images/mobile/m-hight-ggskbg.png") no-repeat;
      background-size: 100% 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .info-l {
        width: 40%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;

        .userhead {
          width: 0.9rem;
          height: 0.9rem;
          background: url("../../assets/images/mobile/m-hight-userhead.png")
            no-repeat;
          background-size: 100% 100%;
          display: flex;
          justify-content: center;
          align-items: center;

          .user-img {
            width: 70%;
            height: 70%;
            border-radius: 50%;
          }
        }
      }

      .info-r {
        width: 60%;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
          display: block;
          width: 1.9rem;
          height: 0.6rem;
        }
      }
    }

    .player-tab {
      position: relative;
      display: flex;
      margin: 0.2rem 0;

      .tab-item {
        width: 0.98rem;
        height: 0.3rem;
        font-size: 0.14rem;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #999999;
        background: url("../../assets/images/OpenBox/popup/tab-item.png")
          no-repeat;
        background-size: contain;
        line-height: 0.3rem;
        text-align: center;
        cursor: pointer;
      }
    }

    .player-box1 {
      width: 100%;
      height: 4.3rem;
      overflow-y: scroll;
      background-color: #09102f;
    }

    .player-box2 {
      width: 100%;
      height: 4.6rem;
      overflow-x: scroll;
      background: url(../../assets/images/OpenBox/popup/r-bg.png) no-repeat;
      background-size: 100% 100%;
      box-sizing: border-box;
      padding: 0.45rem 0.1rem 0.055rem;
    }

    .player-box3 {
      width: 100%;
    }
  }
}

// .player-box {
//   margin-top: 0.08rem;
//   width: 100%;
//   height: calc(100% - 0.65rem);
//   background: url(../../assets/images/OpenBox/popup/r-bg.png) no-repeat;
//   background-size: 100% 100%;
//   box-sizing: border-box;
//   padding: 0.45rem 0.4rem 0.55rem;

//   .player-wrap {
//     height: 100%;
//     overflow-y: auto;
//     box-sizing: border-box;
//     padding: 0.4rem 0;

//     &::-webkit-scrollbar {
//       width: 10px;
//     }

//     &::-webkit-scrollbar-thumb {
//       border-radius: 0.05rem;
//       background-color: #213040;
//     }

//     &::-webkit-scrollbar-track {
//       border-radius: 8px;
//       background-color: #081e29;
//     }
//   }

//   .list {
//     height: 2rem;
//   }
// }
</style>
